<template>
    <div>
                            <div>
                                <form class="form-horizontal" role="form" id="location_form">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group id="input-group-3" label="Country" label-for="input-3">
                                                <b-form-select id="input-3" class="form-select py-2" v-model="location.address.country" :options="countries" required
                                                :class="{'is-invalid': $v.location.address.country.$error}"> </b-form-select>
                                                <div v-if="$v.location.address.country.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.location.address.country.required">Country is required.</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group class="mb-3" id="example text" label="City" label-for="text">
                                                <b-form-input for="text" v-model="location.address.city"
                                                :class="{'is-invalid': $v.location.address.city.$error}"></b-form-input>
                                                <div v-if="$v.location.address.city.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.location.address.city.required">City is required.</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group class="mb-3" id="example text" label="Address" label-for="text">
                                                <b-form-input for="text" v-model="location.address.address"
                                                :class="{'is-invalid': $v.location.address.address.$error}"></b-form-input>
                                                <div v-if="$v.location.address.address.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.location.address.address.required">Address is required.</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group class="mb-3" id="example text" label="State" label-for="text">
                                                <b-form-input for="text" v-model="location.address.state"></b-form-input>
                                            </b-form-group>
                                        </div>
                                     
                                        <!-- <div class="col-md-12">
                                            <b-button type="submit" variant="primary" class="gradientColor radius-10 border-0 px-5">Save</b-button>
                                        </div> -->
                                    </div>
                                </form>
                            </div>

                            <!-- end .border-->
                        </div>
</template>

<script>

import { required, email } from "vuelidate/lib/validators";

export default {
     components: {
           
        },
        data() {
            return {
                
                countries: [{ text: "Select Country", value: null }, "UK", "USA"],
                days: [{ text: "Select Day", value: null }, "Monday", "Tuseday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                categories: ["Breakfast", "Lunch", "Dinner"],
                areas: ["kitchen", "Drive Thru", "Front of House"],
            }
        },
        validations: {
            location: {
                address:{
                    country: {
                    required,
                    },
                    city: {
                    required,
                    },
                    address: {
                    required,
                    },
                }
            }
        },
         computed:{

        location: {
            get() {
                return this.$store.state.locationModule.location
            },
            set(value) {
                this.$store.commit('updateLocation', value)
            }
        },
        },

         methods: {
           checkValidate()
            {
                this.$v.$touch();
            },
            validateErorr()
            {
                return this.$v.$invalid;   
            },
        },
}
</script>
