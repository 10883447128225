<template>
    <div>
                  
                            <div class="row">
                              <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Private scheduling</p>
                                                    <p class="mb-0 pb-0">Employees only see their own scheduled shifts.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                               <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Shift reminders</p>
                                                    <p class="mb-0 pb-0">Send employees a mobile notification “x” hours before their shifts start time. Notification includes shift details and any events going on that day.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                                 <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Only with shifts</p>
                                                    <p class="mb-0 pb-0">For schedules, give the option to only notify employees that have been scheduled for shifts.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                        
                            </div>
                            <div class="row">
                                 <div class="col-md-12">
                                            <b-button type="submit" variant="primary" class="gradientColor radius-10 border-0 px-5">Save</b-button>
                                        </div>
                            </div>
                        </div>
</template>
<script>
export default {
    
}
</script>