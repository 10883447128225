<template>
    <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="notic-wrapper bg-light p-3 radius-20 mb-3 d-flex flex-md-row flex-column gap-2">
                                        <div class="notice-icon"><i class="fas fa-info-circle"></i></div>
                                    <div class="notice-info">
                                    <p class="m-0 p-0">Do the existing availability reasons cover all of your employees' requests? Add more reasons for them to choose from.</p> 
                                    </div>
                                    <div class="notice-link">
                                        <a href="#">View availability reasons</a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Weekly availability</p>
                                                    <p class="mb-0 pb-0">Allow employees to fill in weekly availability ranges. If this is disabled, they will still only be able to fill out repeating availability.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                               <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Allow submissions for published schedules</p>
                                                    <p class="mb-0 pb-0">If checked, employees can submit or change their weekly availability for weeks where a schedule has been published.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                                 <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Availability visible to employees</p>
                                                    <p class="mb-0 pb-0">If disabled, only managers will see availability tab.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                              <div class="col-md-12">
                                     <b-form-group>
                                                <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                                                    <p class="mb-0 pb-0 fw-bold">Availability approvals</p>
                                                    <p class="mb-0 pb-0">If checked, any availability posted by employees would require manager approval.</p>
                                                </b-form-checkbox>
                                     </b-form-group>
                              </div>
                            </div>
                            <div class="row">
                                 <div class="col-md-12">
                                            <b-button type="submit" variant="primary" class="gradientColor radius-10 border-0 px-5">Save</b-button>
                                        </div>
                            </div>
                        </div>
</template>
<script>
export default {
    
}
</script>