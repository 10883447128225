<template>
    <div>
                  
                            <div class="row">
                              <div class="col-md-12">
                            <div class="table-responsive mb-0">
                                    <b-table
                                        table-class="table table-centered datatable table-card-list"
                                        thead-tr-class="bg-transparent"
                                        :items="invoicelist"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                    >
                                   
                                        <template v-slot:cell(id)="data">
                                            <a href="javascript: void(0);" class="text-dark fw-bold">
                                                {{ data.item.id }}
                                            </a>
                                        </template>
                                            <template v-slot:cell(userID)="data">
                                            <a href="javascript: void(0);" class="text-dark ">
                                                {{ data.item.userID }}
                                            </a>
                                        </template>

                                    

                                        <template v-slot:cell(name)="data">
                                            <a href="#" class="text-body">{{ data.item.name }}</a>
                                        </template>
                                        
                                        <template v-slot:cell(userEmail)="data">
                                            <a href="#" class="text-body">{{ data.item.userEmail }}</a>
                                        </template>
                                         <template v-slot:cell(contact)="data">
                                            <a href="#" class="text-body">{{ data.item.contact }}</a>
                                        </template>
                                        <template v-slot:cell(totalEarning)="data">
                                            <a href="#" class="text-body">{{ data.item.totalEarning }}</a>
                                        </template>
                                        <template v-slot:cell(targetEarning)="data">
                                            <a href="#" class="text-body">{{ data.item.targetEarning }}</a>
                                        </template>
                                     
                                        <template v-slot:cell(action)>
                                            <ul class="list-inline mb-0">
                                                <li class="list-inline-item">
                                                    <a href="javascript:void(0);" class="px-2 btn gradientColor text-white" v-b-tooltip.hover title="Edit">
                                                       Save
                                                    </a>
                                                </li>
                                           
                                            </ul>
                                        </template>
                                    </b-table>
                                </div>
                         
                            </div>
                            </div>
                            <div class="row">
                                 <div class="col-md-12">
                                            <b-button type="submit" variant="primary" class="gradientColor radius-10 border-0 px-5">Save</b-button>
                                        </div>
                            </div>
                        </div>
</template>

<script>
export default {
    methods:{
        onFiltered(){
            
        }
    },
    data(){
        return{
              //---------Table Data
                invoicelist: [
                    {
                        id: "1",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Connie Franco",
                       
                  },
                    {
                        id: "2",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Paul Reynolds",
                       
                  },
                    {
                        id: "3",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Ronald Patterson",
                       
                    },
                    {
                        id: "4",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Adella Perez",
                       
                  },
                    {
                        id: "5",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Theresa Mayers",
                       
                  },
                    {
                        id: "6",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Michael Wallace",
                       
                  },
                    {
                        id: "7",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Oliver Gonzales",
                       
                        
                    },
                    {
                        id: "8",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "David Burke",
                       
                  },
                    {
                        id: "9",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Willie Verner",
                       
                        
                    },
                    {
                        id: "10",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Felix Perry",
                       
                  },
                    {
                        id: "11",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Virgil Kelley",
                       
                  },
                    {
                        id: "12",
                        userID: "CXD0912",
                        userEmail:"test@gmail.com",
                        contact:"+1 6587 3912",
                        totalEarning: "$50",
                        targetEarning: "$70",
                      
                        name: "Matthew Lawler",
                       
                        
                    },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                fields: [
                    {
                        key: "check",
                        label: "",
                    },
                    {
                        key: "id",
                        label: "Sr.#",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Member Name",
                        sortable: true,
                    },
                     {
                        key: "userID",
                        label: "User ID",
                        sortable: true,
                    },
                      {
                        key: "userEmail",
                        label: "User Email",
                        sortable: true,
                    },
                       {
                        key: "contact",
                        label: "Contact",
                        sortable: true,
                    },
                      {
                        key: "totalEarning",
                        label: "Total Earning",
                        sortable: true,
                    },
                      {
                        key: "targetEarning",
                        label: "Target Earning",
                        sortable: true,
                    },
        
                    "action",
                ],
        }
    }
}
</script>