<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import {mapState} from "vuex";
    import {locationMethods} from "@/state/helpers";

    import appConfig from "@/app.config";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import Create from "@/views/pages/admin/locations/components/Create";
    import Address from "@/views/pages/admin/locations/components/Address";
    import Availability from "@/views/pages/admin/locations/components/Availability";
    import Schedule from "@/views/pages/admin/locations/components/Schedule";
    import ShiftBonus from "@/views/pages/admin/locations/components/ShiftBonus";
    import PastEmployeeHistory from "@/views/pages/admin/locations/components/PastEmployeeHistory";
    
    export default {
        page: {
            title: "Create Location",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,FormWizard,TabContent,
            Create,Address,
            // Availability,Schedule,ShiftBonus,PastEmployeeHistory
        },
         computed: {
             ...mapState({
            location: state => state.locationModule.location
        }),
    },
        mounted()
        {
            this.resetState();
        },
        
        data() {
            return {
                title: "Create Location",
                items: [
                    {
                        text: "Locations",
                    },
                    {
                        text: "Create",
                        active: true,
                    },
                ],
                
                loadingWizard: false,
                currentTabComponent: 'Create',
                tabs: [
                        {
                            id:1,
                            desc: 'Create Location',
                            component_name: 'Create',
                            icon: 'fa-file'
                        },
                        {
                            id:2,
                            desc: 'Address',
                            component_name: 'Address',
                            icon: 'fa-file'
                        },
                        // {
                        //     id: 3,
                        //     desc: 'Availability',
                        //     component_name: 'Availability',
                        //     icon: 'fa-money-bill-wave-alt',
                        // },
                        // {
                        //     id: 4,
                        //     desc: 'Schedule',
                        //     component_name: 'Schedule',
                        //     icon: 'fa-file',
                        // },
                        // {
                        //     id: 5,
                        //     desc: 'Shift Bonus',
                        //     component_name: 'ShiftBonus',
                        //     icon: 'fa-file',
                        // },
                        // {
                        //     id: 6,
                        //     desc: 'Past Employees History',
                        //     component_name: 'PastEmployeeHistory',
                        //     icon: 'fa-file',
                        // },

                ],
            };
        },
 
        methods: {

            ...locationMethods,
            
            onComplete: function(){
      
                  let self = this;
                if (self.loadingWizard) {
                return;
                }
                    self.location.restaurant_id=self.currentUser.restaurant.id;
                    self.location.time_zone_id=self.location.time_zone.id;
                
                self.loadingWizard = true;
                
                this.$refs.locationComponent[1].checkValidate();
                if(this.$refs.locationComponent[1].validateErorr()){
                    return;
                }else{
                    delete self.location.time_zone;
                    self.$axios.post('/restaurant/location/store', {
                    location:self.location
                    
                    })
                    .then(response => {
                        let data = response.data;

                        self.triggerSwal(data.message, "success");
                    self.$router.push("/locations");
                  
                    })
                    .catch(error => {
                   
                        self.handleErrorResponse(error.response, "error");

                    
                    })
                    .finally(function () {
                        self.loadingWizard = false;
                        self.resetState();
                    });
                }
             },

            setLoading: function(value) {
                this.loadingWizard = value
            },
            handleValidation: function(isValid, tabIndex){
                console.log('Tab: '+tabIndex+ ' valid: '+isValid)
            },
            validateAsync:function() {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.$refs.locationComponent[0].checkValidate();
                        if(this.$refs.locationComponent[0].validateErorr()){
                            reject(true)
                        }else{
                            resolve(true) 
                        }
                    }, 1000)
                })
            },

        //      getIdFromArray(arrray){
        //       let id_array= arrray.map((item) => {
        //           return item.id
                  
        //         });
        //         return id_array;
        //    },
  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title"/>
        <div class="row">

        <div class="card">
        <div class="card-body">
         <form-wizard @on-complete="onComplete"
                @on-loading="setLoading"
                @on-validate="handleValidation"
                shape="circle"
                color="#70C213"
                step-size="xs" errorColor="#FFD2D2">
           
                <template v-for="tab in tabs">
                  
                <tab-content :key="tab.id" :title="tab.desc"
                            :before-change="validateAsync"
                            >
                        <component :is="tab.component_name" ref="locationComponent"></component>
                </tab-content>
                </template>
            
      
      <div class="loader" v-if="loadingWizard"></div>
    </form-wizard>
        </div>
        </div>
        </div>
    </Layout>
</template>
