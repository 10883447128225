<template>
  <div>
    <form class="form-horizontal" role="form" id="location_form">
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            class="mb-3"
            id="example text"
            label="Location Name"
            label-for="text"
          >
            <b-form-input
              for="text"
              v-model="location.name"
              :class="{ 'is-invalid': $v.location.name.$error }"
            ></b-form-input>
            <div v-if="$v.location.name.$error" class="invalid-feedback">
              <span v-if="!$v.location.name.required">Name is required.</span>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="input-group-3"
            label="Start a week on"
            label-for="input-3"
          >
            <b-form-select
              id="input-3"
              class="form-select py-2"
              v-model="location.start_day"
              :options="days"
              required
              :class="{ 'is-invalid': $v.location.start_day.$error }"
            >
            </b-form-select>
            <div v-if="$v.location.start_day.$error" class="invalid-feedback">
              <span v-if="!$v.location.start_day.required"
                >Week Start Day is required.</span
              >
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            id="input-group-3"
            label="Lock Date"
            label-for="input-3"
          >
            <!-- <b-form-input id="input-3" type="datetime-local" class="form-select py-2" v-model="location.lock_date"  required> </b-form-input> -->
            <b-form-select
              id="input-3"
              class="form-select py-2"
              v-model="location.lock_date"
              :options="days"
              required
              :class="{ 'is-invalid': $v.location.lock_date.$error }"
            >
            </b-form-select>
            <div v-if="$v.location.lock_date.$error" class="invalid-feedback">
              <span v-if="!$v.location.lock_date.required"
                >Lock Date is required.</span
              >
            </div>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <label>Time Zone</label>
          <vue-multiselect
          :class="{'is-invalid': $v.location.time_zone.$error}"
            v-model="location.time_zone"
            hide-selected
            :load-more="timeZones.nextPageUrl"
            :loading="timeZones.loading"
            :options="timeZones.data"
            @on-search="searchTimeZones"
            @on-open="fetchTimeZones"
            @on-reaching-end="fetchTimeZonesOnReachingEnd"
            internal-search
            showNoResult
            :id="'timezones'"
            searchable
            label="text"
          >
          </vue-multiselect>
          <div v-if="$v.location.time_zone.$error" class="invalid-feedback" >
                                     <span v-if="!$v.location.time_zone.required">Select Time Zone</span> 
           </div>
        </div>

        <!-- <div class="col-md-6 mt-2">
                  <b-form-group>
                      <b-form-checkbox value="accepted" unchecked-value="not_accepted" class="mb-3" checked plain>
                          <p class="mb-0 pb-0 fw-bold">Mobile SMS notifications</p>
                          <p class="mb-0 pb-0">Allow SMS notifications for mobile numbers in this country. To switch between SMS and push, employees can head to notification settings in the mobile app.</p>
                      </b-form-checkbox>
                  </b-form-group>
              </div> -->
        <!-- <div class="col-md-12">
                  <b-button type="submit" variant="primary" class="gradientColor radius-10 border-0 px-5">Save</b-button>
              </div> -->
      </div>
    </form>
  </div>
  <!-- end .border-->
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import VueMultiselect from "@/components/general/vue-multiselect";
import _ from "lodash";

export default {
  components: {
    VueMultiselect,
  },
  data() {
    return {
      countries: [{ text: "Select Country", value: null }, "UK", "USA"],
      days: [
        { text: "Select Day", value: null },
        "Monday",
        "Tuseday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      categories: ["Breakfast", "Lunch", "Dinner"],

      dyn_areas: [],
      timeZones: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      params: { types: JSON.stringify(["timeZonesList"]) },
    };
  },
  validations: {
    location: {
      name: {
        required,
      },
      start_day: {
        required,
      },
      lock_date: {
        required,
      },
      time_zone: {
        required,
      },
    },
  },

  computed: {
    location: {
      get() {
        return this.$store.state.locationModule.location;
      },
      set(value) {
        this.$store.commit("updateLocation", value);
      },
    },
    loadMore() {
               return !!this.nextPageUrl;
           },
  },

  mounted() {
    this.fetchTimeZones();
  },

  methods: {
    checkValidate() {
      this.$v.$touch();
    },
    validateErorr() {
      return this.$v.$invalid;
    },
    AddformData() {
      this.area_id++;
      this.location.areas.push({
        id: this.area_id,
        area_id: null,
        roles: [
          {
            role_id: null,
          },
        ],
      });
    },
    AddRole(id) {
      this.location.areas.map((area) => {
        if (area.id == id) {
          area.roles.push({
            role: null,
          });
        }
      });
    },

    deleteRow(index) {
      this.location.areas.splice(index, 1);
    },
    deleteRole(index, id) {
      this.location.areas.map((area) => {
        if (area.id == id) {
          area.roles.splice(index, 1);
        }
      });
    },


    resetData() {
      this.timeZones.data = [];
      // this.timeZones.nextPageUrl = "/get-static-data";
    },

    refetchTimeZones() {
      this.resetData();
      this.fetchTimeZones();
    },

    searchTimeZones: _.debounce(function (search) {
      this.resetData();
      this.params.search = search;
      this.fetchTimeZones();
    }, 400),

    fetchTimeZonesOnReachingEnd() {
      this.fetchTimeZones(this.timeZones.nextPageUrl);
    },

    fetchTimeZones(url) {
      url = url ?? "/get-static-data";
      this.$axios
        .get(url, { params: this.params })
        .then((response) => {
          let result = response.data.payload.data.timeZonesList;
          // if(Array.isArray(result.data)){
          //   this.timeZones.data.push(...result.data);
          // }else{
          //   for (const [key, value] of Object.entries(result.data)) {
          //     this.timeZones.data.push(value);
          //       }
          // }
              this.timeZones.data.push(...result.data);
          this.timeZones.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error, "error");
        })
        .finally(function () {});
    },

    showRole(e, id) {
      this.location.areas.map((area) => {
        if (area.id == id) {
          this.dyn_areas.map((d_area) => {
            if (e.target.value == d_area.id) {
              area.area_roles = d_area.area_role;
            }
          });
        }
      });
    },
  },
};
</script>
